.stockCard {
    transition: all .25s ease-in;
    box-shadow: 1px 2px 10px rgba(41, 41, 42, 0.5);
    margin: 10px;

    p {
        color: #303030;
    }

    .carddetail {
        padding: 15px;
    }

    .cardTitle {
        background-color: #bc0067;
        color: #fff;
        padding: 15px;

        p,
        h3 {
            color: #fff !important;
        }

        a {
            color: #fff !important;
        }
    }
}

.taskCard {
    transition: all .25s ease-in;
    box-shadow: 1px 2px 10px rgba(41, 41, 42, 0.5);
    margin: 10px;

    p {
        color: #303030;
    }

    .carddetail {
        padding: 15px;
    }

    .cardTitle {
        background-color: #162040;
        color: #fff;
        padding: 15px;

        p {
            color: #fff;
        }
    }
}

.tripcard {
    transition: all .25s ease-in;
    box-shadow: 1px 2px 10px rgba(41, 41, 42, 0.5);
    margin: 10px;

    p {
        color: #303030;
    }

    .carddetail {
        padding: 15px;
    }

    .cardTitle {
        background-color: #162040;
        color: #fff;
        padding: 15px;

        p {
            color: #fff;
        }
    }
}

.rejectbutton {
    background: #C12026 !important;
    border: 0;
    color: #fff;
    margin-right: 20px;
}

.buttonFull {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
}

.accept {
    background: #06c167 !important;
    border: 0;
}