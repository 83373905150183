.App {
  text-align: center;
}

/* Login Page*/

.loginBox {
  padding: 24px;
  background: #fff;
  border-radius: 10px;
}

.loginLogo {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;

  img {
    width: 126px;
    padding: 5px;
  }
}

.logoColor {
  fill: #bc0067 !important;
}

.site-layout-background {
  padding: 24px;
}

.site-layout .site-layout-background {
  margin-bottom: 15px;
}

.ant-layout-sider-trigger,
.ant-layout-sider {
  background: #bc0067 !important;
}

.ant-menu-item {
  text-align: center;
}

.ant-menu-item:hover a,
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #bc0067 !important;
}

.ant-menu-item:hover .icons,
.ant-menu-item-selected .icons,
.ant-menu-item-selected .icons {
  color: #bc0067 !important;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: rgba(188, 0, 103, 0.1) !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #bc0067 !important;
}

.ant-menu-item {
  font-weight: bold;
}

.ant-menu-item {
  height: 80px !important;
  line-height: 40px;
}

.ant-menu-item {
  padding: 10px 0px !important;
}

.ant-col {
  padding-bottom: 10px;
}

.LinksCard {
  margin: 5px;
  background: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 2px;
  font-weight: bold;
  background-image: linear-gradient(to left,
      transparent,
      transparent 50%,
      #bc0067 50%,
      #bc0067);


  background-position: 100% 0;
  background-size: 200% 100%;
  transition: all .25s ease-in;
  box-shadow: 1px 2px 10px rgba(41, 41, 42, 0.5);

  a {
    color: #bc0067 !important;
  }

  .icons {
    display: block;
    padding: 5px;
    color: #bc0067;
  }

}

.LinksCardInner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  span {

    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .cardText {
    text-align: left;
    font-size: 1.2em;
    font-weight: 500;
  }
}

.LinksCard:hover {
  background-position: 0 0;

  a {
    color: #fff !important;
  }

  .icons {
    display: block;
    padding: 5px;
    color: #fff;
  }
}

.DetailCard {
  margin: 5px;
  text-align: center;
  background: #f3f3f3;
  color: #bc0067;
  padding: 5px;
  min-height: 100px;
  border-radius: 5px;
}

.DetailCard h3 {
  font-weight: bold;
  font-size: 32px;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  color: #bc0067;
}

.DetailCard p {
  font-size: 18px;
  margin: 0;
}

.DetailCard span {
  font-size: 8px;
  margin: 0;
}

h2 {
  color: #000000 !important;
}

.titles {
  font-weight: 600;
  color: #bc0067 !important;
  ;
}

.titles span {
  font-weight: 400 !important;
}

.ant-menu-item .icons {
  display: block;
  font-size: 32px;
  color: #000;
}

.ant-btn-primary {
  color: #fff;
  background: #bc0067 !important;
  border-color: #bc0067 !important;
}

@media only screen and (max-width: 768px) {
  .ant-select.ant-select-single.ant-select-show-arrow {
    width: 100% !important;
  }

  .mobileCenter {
    text-align: center;
  }

  .LinksCardInner .cardText {
    font-size: 2em;
  }

  .ant-layout-sider {
    display: none;
  }

  .LinksCard {
    font-size: 10px;
    line-height: 1.2;
    min-height: 90px;
  }

  .LinksCard .icons {
    font-size: 32px;
  }

  .ant-layout-footer {
    display: none !important;
  }

  .mobile-navbar {
    position: fixed;
    bottom: 0;
    background: #ffffff;
    z-index: 20;
    width: 100%;
    padding: 15px 0 15px;
    -webkit-box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 0px 6px 0px rgba(0, 0, 0, 0.5);

    ul {
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
    }

    li {
      list-style: none;
      text-align: center;
    }

    a span {
      display: block;

    }

    .menu-text {
      color: #303030;
      font-size: 10px;
      font-weight: bold;
    }

    .icons {
      font-size: 28px;
      color: #303030;
    }

    .active .icons,
    .active .menu-text {
      color: #bc0067 !important;
    }
  }

  .ant-layout-header {
    background: #bc0067 !important;
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-flow: column wrap;

  }

  .mobile-logo {
    text-align: center;
    width: 100%;
    display: block;
    position: relative;
    padding-left: 15px;
  }

  .mobile-only {
    .ant-col {
      padding-bottom: 0;
    }
  }

}

@media only screen and (min-width: 768px) {
  .LinksCard .icons {
    font-size: 60px;
  }

  .mobile-navbar {
    display: none !important;
  }

  .mobile-only {
    display: none;
  }
}

.ant-input:placeholder-shown {
  text-overflow: ellipsis;
  margin-bottom: 15px;
  margin-top: 5px;
}

label {
  font-weight: 600;
  padding-top: 10px !important;
  color: #747474 !important;
}

p {
  color: #007003;
}

.Login {
  color: #000;
  background: #dddd;
  text-align: center;
}

.accountMenu {
  ul {
    margin: 0;
    padding: 0;
  }
}

.accountLink {
  min-height: 120px;
  background: #fff;
  text-align: center;
  padding: 15px;
}

.logout {
  font-weight: 600;
  color: #000000;
  margin-top: 15px;
  padding: 10px 15px;
  border: 1px solid #000000;
  margin-bottom: 15px;
}

.backButton,
.backButton:hover {
  background: #303030 !important;
  color: #fff;
  padding: 5px 10px;
}

.processOrder {
  list-style: none;
  padding: 15px;

  li {
    padding: 5px;
  }

  li div {
    display: inline-flex;
    padding: 10px;
    text-align: center;
  }

  .content {
    width: 100px;
  }
}

.processBar {
  background: #f0f0f0;
  border-radius: 5px;
}

.display-none {
  display: none;
}

.no {
  display: none;
}

.ant-input {
  max-width: 383px !important;
  display: block !important;
  margin: 10px 0;
}

.ant-select {
  width: 350px !important;
  height: 40px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px !important;
}

label {
  line-height: 40px;
}

.dataTable {

  tr,
  td,
  th {
    padding: 5px;
    border: 1px solid #343434;
    margin: 5px;
  }
}

.display-none {
  display: none !important;
}

.table {
  display: block;

  @media screen and (max-width: 600px) {
    &-thead {
      display: none;
    }

    tr:nth-child(even) {
      background: #eaeaea;
      padding: 15px;
    }

    &-thead>tr,
    &-tbody>tr {

      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      >th,
      >td {
        display: block;
        width: auto !important;
        border: none;
        padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

.displaynone {
  display: none;
}

.ant-drawer-header {
  background: #bc0067 !important;
}

.ant-drawer-title {
  color: #fff !important;
}



.loader {
  position: fixed;
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  height: 100vh;
  z-index: 100;
  left: 0px;
  text-align: center;
  padding-top: 50vh;
}

.noloader {
  display: none !important;
}

.Reject {
  display: none;
}